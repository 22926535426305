export interface Prompt {
    Title: string;
    Description: string;
    Prompt: string;
  }
export interface PresetPromptCategory {
[key: string]: Prompt;
}
  
export interface PresetPromptBase {
[key: string]: PresetPromptCategory;
}

/* Add new prompt suggestions to our pool. 
 * We currently have 3 categories loaded on the initial page, showing only the title and description.
 * 
 * Dynamic resizing of prompt cards was considered, but having mismatching number of lines didn't look good.
 * Thus, each suggestion has a title on one line and a description on two lines.
 * 
 * When adding new suggestions, follow this format or adjust the card size dynamically? */
const presetPrompts:PresetPromptBase = {
        "Creative Writing": {
            "p0": {
                "Title": "Escrita de documentos",
                "Description": "Escrever e-mail profissional personalizado",
                "Prompt": "Me ajude na escrita de um e-mail. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            },
            "p1": {
                "Title": "Escrita de documentos",
                "Description": "Escrever currículo profissional personalizado",
                "Prompt": "Irei concorrer a uma vaga em um processo seletivo. Me ajude na escrita de um currículo profissional. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            },
            "p2": {
                "Title": "Escrita de documentos",
                "Description": "Escrever post de marketing digital",
                "Prompt": "Preciso aumentar as vendas do meu produto. Produza um pitch atraente para o meu produto. Irei postar o texto produzido em minha rede social. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            }
        },
        "Code": {
            "p0": {
                "Title": "Programação",
                "Description": "Produzir website pessoal responsivo",
                "Prompt": "Preciso de um website para apresentar minhas conquistas profissionais. Produza um website em um único arquivo com CSS e HTML. O website precisa ser responsivo. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            },
            "p1": {
                "Title": "Programação",
                "Description": "Auxiliar no estudo de linguagem de programação",
                "Prompt": "Estou estudando uma nova linguagem de programação. Seja um guia para este estudo, fornecendo dicas e tirando dúvidas. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            },
            "p2": {
                "Title": "Programação",
                "Description": "Criar projeto de programação para portfólio",
                "Prompt": "Gostaria de incrementar meu portfólio como profissional de programação. Me auxilie na produção de um projeto simples para demonstrar minhas habilidades. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            }
        },        
        "Brazilian Knowledge": {
            "p0": {
                "Title": "Guia brasileiro",
                "Description": "Auxiliar no estudo da história brasileira",
                "Prompt": "Estou estudando a história do Brasil. Seja um guia para este estudo, fornecendo dicas e tirando minhas dúvidas. Contudo, primeiro, me sugira acontecimentos históricos do Brasil."
            },
            "p1": {
                "Title": "Guia brasileiro",
                "Description": "Produzir cronograma de viagem no Brasil",
                "Prompt": "Estou planejando viajar pelo Brasil. Sugira pontos turísticos pouco conhecidos, produzindo um cronograma de viagem para uma semana. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            },
            "p2": {
                "Title": "Guia brasileiro",
                "Description": "Sugerir pontos turísticos em Campinas",
                "Prompt": "Estou passeando pela cidade de Campinas, Brasil. Contudo, não conheço esta cidade. Sugira pontos turísticos pouco conhecidos. Contudo, primeiro, me faça três perguntas que te ajudarão nesta tarefa."
            }
        }
}

export default presetPrompts