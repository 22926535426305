import React, { useEffect } from 'react';

interface CheckoutRedirectProps{
  model_variant: string;
}

const CheckoutRedirect: React.FC<CheckoutRedirectProps> = ({model_variant}) => {

  useEffect(() => {
    const fetchURL = async () => {
      try {

        const payload = {
          "model": model_variant
        }
        
        // fetch url from lambda function
        const response = await fetch('https://kbecavd7m2mzy4ud5gejckqihq0hcasp.lambda-url.us-east-1.on.aws', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
          });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const { checkout_url } = data;

        if (checkout_url) {
          window.location.href = checkout_url; // Redirects the user to the generated URL
        } else {
          console.error("URL not found in response");
        }
      } catch (error) {
        console.error("Error fetching URL:", error);
      }
    };

    fetchURL();
  }, []);

  return <div>Redirecting...</div>; // Placeholder content, this will be shown briefly before the user is redirected
};

export default CheckoutRedirect;
