import React, { useEffect, useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE } from '../shared/Constants';
import { PlanInfo } from '../shared/interfaces';
import { Divider } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';

export interface BillingDialogProps {
  open: boolean;
  setOpen: (p: boolean) => void;
}

export default function BillingDialog({ open, setOpen }: BillingDialogProps) {

  const handleClose = () => {
    setOpen(false);
  };


  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [plan, setPlan] = useState<PlanInfo>();
  const [checkoutLink, setCheckoutLink] = useState<string>("");
  const [portalLink, setPortalLink] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {

      const getCheckoutLink = async () => {
        const url = API_BASE + "/api/billing/checkout";
        const access_token = await getAccessTokenSilently()
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            "authorization": `Auth ${access_token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          const checkout_url = data["url"]
          // redirect user to url
          setCheckoutLink(checkout_url);
        }
      }

      const getPortalLink = async () => {
        const url = API_BASE + "/api/billing/customer-portal";
        const access_token = await getAccessTokenSilently()
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            "authorization": `Auth ${access_token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          const portal_url = data["url"]
          // redirect user to url
          setPortalLink(portal_url);
        }
      }
      const fetchStatus = async () => {
        const url = API_BASE + "/api/billing/status";
        setLoading(true);
        const access_token = await getAccessTokenSilently();
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Auth ${access_token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPlan(data);
          if (data.plan_status == "free") {
            await getCheckoutLink();
          } else {
            await getPortalLink();
          }
        }
        setLoading(false);

      };

      fetchStatus();

    }
  }, [open]);


  const header_string = plan == undefined ? "Meu plano" : plan.plan_status == "free" ? "Nenhum meio de pagamento verificado - sem acesso à API; apenas chat web gratuito" : ""


  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}>
      <DialogTitle>{header_string}</DialogTitle>

      <Divider />


      <DialogContent>

        {!loading &&

          <>

            <PlanInfoHeaderArea>

              <span>Maritalk API</span>

            </PlanInfoHeaderArea>



            <CheckoutButton onClick={() => { plan?.plan_status == "free" ? window.location.href = checkoutLink : window.location.href = portalLink }}>
              {plan?.plan_status == "free" ? "Validar forma de pagamento" : "Gerencie forma de pagamento"}
            </CheckoutButton>



            <PlanBenefitsArea>

              <PlanBenefitItem>

                <CheckIcon />

                <span>Acesso ao nosso modelo via API</span>

              </PlanBenefitItem>

              <PlanBenefitItem>

                <CheckIcon />

                <span>R$20 em créditos de API para testar (aprox. 2.800 páginas de texto)</span>

              </PlanBenefitItem>

            </PlanBenefitsArea>


            {/* {plan?.plan_status == "premium" && 
              <>
                  {portalLink && <Button onClick={()=>{window.location.href=portalLink}}>Gerencie sua assinatura</Button>}
              </>
          } */}


          </>
        }

        {loading && <DialogContentText>Carregando...</DialogContentText>}

      </DialogContent>

      <Divider />

      <DialogTitle style={{ color: 'red', textAlign: 'center', fontSize: '1rem' }}>
        <div>
          <span>O sistema de planos pós-pagos será descontinuado em <b>30 de setembro de 2024</b></span>
        </div>
        <div>
          <span>Utilize o novo sistema de créditos em <a href="https://plataforma.maritaca.ai/" target="_blank" style={{ color: 'red' }}><b>Plataforma Maritaca AI</b></a></span>
        </div>
      </DialogTitle>

    </Dialog>
  );
}

const PlanInfoHeaderArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    width:100%;

    font-size: 1.5rem;
`

const PlanBenefitsArea = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width:100%;

`

const WarningSuspendedText = styled.span`
  display:block;
  margin: 10px;
  color:red;
  font-size: 1rem;
`

const PlanBenefitItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    padding: 10px;
    width:100%;

    font-size: 1rem;

`



const CheckoutButton = styled.button`
    margin: 10px;
    margin-bottom: 30px;
    width:100%;

    cursor: pointer;
    align-items: center;
    background-color: initial;
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    box-sizing: border-box;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 18px;
    height: 52px;
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 0 32px;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
`


const KeyDisplayArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    y-overflow: auto;
    width:100%;
`

const KeyDisplayItem = styled.div`
    display: flex;
    border: 1px solid black;
    margin:5px;
    width:80%;
    align-items: center;

`

const KeyDisplayItemLabel = styled.div`
    font-weight: bold;
    background: black;
    color: white;
    display: flex;
    align-items: center;
    padding: 5px;
    width:40%;
    overflow-x: hidden;
`

const KeyDisplayItemValue = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    width:40%;
    overflow-x: hidden;
`

const KeyDisplayDeleteIconArea = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    background: red;
    margin-left: auto;
`
