import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const TickIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      stroke='currentColor'
      fill='none'
      strokeWidth='2'
      viewBox='0 0 24 24'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='h-4 w-4'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <polyline points='20 6 9 17 4 12'></polyline>
    </svg>
  );
};

const CopyIcon = () => {
    return (
      <svg
        stroke='currentColor'
        fill='none'
        strokeWidth='2'
        viewBox='0 0 24 24'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='h-4 w-4'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'></path>
        <rect x='8' y='2' width='8' height='4' rx='1' ry='1'></rect>
      </svg>
    );
  };
  

const CodeBlock = ({
  lang,
  codeChildren,
}: {
  lang: string;
  codeChildren: React.ReactNode & React.ReactNode[];
}) => {
  const codeRef = useRef<HTMLElement>(null);

  return (
    <CodeBlockArea>
      <CodeBar lang={lang} codeRef={codeRef} />
      <CodeBlockBody>
        <pre style={{"margin": 0}}>
        <StyledCode ref={codeRef} className={`!whitespace-pre hljs language-${lang}`}>
          {codeChildren}
        </StyledCode>
        </pre>
      </CodeBlockBody>
    </CodeBlockArea>
  );
};

const CodeBar = React.memo(
  ({
    lang,
    codeRef,
  }: {
    lang: string;
    codeRef: React.RefObject<HTMLElement>;
  }) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    return (
      <CodeBarHeader>
        <span className=''>{lang}</span>
        <CopyButton
          aria-label='copy codeblock'
          onClick={async () => {
            const codeString = codeRef.current?.textContent;
            if (codeString)
              navigator.clipboard.writeText(codeString).then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000);
              });
          }}
        >
          {isCopied ? (
            <>
              <TickIcon />
              Copied!
            </>
          ) : (
            <>
              <CopyIcon />
              Copy code
            </>
          )}
        </CopyButton>
      </CodeBarHeader>
    );
  }
);
export default CodeBlock;

const CodeBarHeader = styled.div`
    display: flex;
    align-items: space-between;
    position: relative;
    color: #e5e7eb; /* equivalent to text-gray-200 */
    background-color: #1f2937; /* equivalent to bg-gray-800 */
    padding: 0.5rem 1rem; /* equivalent to px-4 py-2 */
    font-size: 0.75rem; /* equivalent to text-xs */
    font-family: sans-serif; /* equivalent to font-sans */
`;


const CodeBlockArea = styled.div`
  background-color: #000000;
  border-radius: 0.375rem;
  max-width: 95%;
  overflow-x: auto;
`;


const CodeBlockBody = styled.div`
  padding: 1rem; /* equivalent to p-4 */
  max-width: 100%; /* equivalent to w-full */
  overflow-y: auto; /* equivalent to overflow-y-auto */
`;

const CopyButton = styled.button`
  display: flex; /* equivalent to flex */
  margin-left: auto; /* equivalent to ml-auto */
  gap: 0.5rem; /* equivalent to gap-2 */
  background-color: transparent; /* equivalent to bg-transparent */
  color: #e5e7eb; /* equivalent to text-gray-200 */
  border: none; 
  cursor: pointer;
`;

const StyledCode = styled.code`
  white-space: pre !important; /* equivalent to !whitespace-pre */
  color: white;
`;