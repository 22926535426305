
import { ToastOptions } from "react-toastify";
export const API_BASE = process.env.NODE_ENV === 'development' ? "http://localhost:8000" : "";

export const COMPANY_WEBSITE ="https://maritaca.ai";
export const PLATFORM_WEBSITE = "https://plataforma.maritaca.ai";
export const API_PUBLIC_REPOSITORY ="https://github.com/maritaca-ai/maritalk-api";
export const PRODUCT_TERMS_AND_POLICY ="https://www.maritaca.ai/termos-e-condi%C3%A7%C3%B5es";

export const TOAST_CONFIG:ToastOptions = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

export const API_KEY_TOAST_CONFIG:ToastOptions = {
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
}

export const CodeBlockTheme = {
    lineNumberColor: "#ccc",
    lineNumberBgColor: "#222",
    backgroundColor: "#222",
    textColor: "#ccc",
    substringColor: "#00ff00",
    keywordColor: "#0077ff",
    attributeColor: "#ffaa00",
    selectorTagColor: "#0077ff",
    docTagColor: "#aa00ff",
    nameColor: "#f8f8f8",
    builtInColor: "#0077ff",
    literalColor: "#ffaa00",
    bulletColor: "#ffaa00",
    codeColor: "#ccc",
    additionColor: "#00ff00",
    regexpColor: "#f8f8f8",
    symbolColor: "#ffaa00",
    variableColor: "#ffaa00",
    templateVariableColor: "#ffaa00",
    linkColor: "#aa00ff",
    selectorAttributeColor: "#ffaa00",
    selectorPseudoColor: "#aa00ff",
    typeColor: "#0077ff",
    stringColor: "#00ff00",
    selectorIdColor: "#ffaa00",
    quoteColor: "#f8f8f8",
    templateTagColor: "#ccc",
    deletionColor: "#ff0000",
    titleColor: "#0077ff",
    sectionColor: "#0077ff",
    commentColor: "#777",
    metaKeywordColor: "#f8f8f8",
    metaColor: "#aa00ff",
    functionColor: "#0077ff",
    numberColor: "#ffaa00",
  };

  export const codeLanguageSubset = [
    'python',
    'javascript',
    'java',
    'go',
    'bash',
    'c',
    'cpp',
    'csharp',
    'css',
    'diff',
    'graphql',
    'json',
    'kotlin',
    'less',
    'lua',
    'makefile',
    'markdown',
    'objectivec',
    'perl',
    'php',
    'php-template',
    'plaintext',
    'python-repl',
    'r',
    'ruby',
    'rust',
    'scss',
    'shell',
    'sql',
    'swift',
    'typescript',
    'vbnet',
    'wasm',
    'xml',
    'yaml',
  ];