import React, { createContext, useEffect, useState } from 'react';
import { useAuth0, AuthorizationParams } from "@auth0/auth0-react";
import { API_BASE } from '../Constants';
import Pusher from 'pusher-js';
import { CurrentChatInfo } from '../types/CurrentChatInfo';

export interface childrenProps {
  children: React.ReactNode;
}

export interface ChatControlProviderProps {
  activeChatID: CurrentChatInfo;
  setActiveChatID: (p: CurrentChatInfo) => void;
  modelChat: string;
  setModelChat: (p: string) => void;
  userChats: object[];
  setUserChats: (p: object[]) => void;
  messageHistory: object[];
  setMessageHistory: (p: object[]) => void;
  loadingHistory: boolean;
  setLoadingHistory: (p: boolean) => void;
  pusherObj: Pusher | undefined;
}

const ChatControlContext = createContext<ChatControlProviderProps | null>(null);

export const ChatControlProvider = ({ children }: childrenProps) => {
  const [activeChatID, setActiveChatID] = useState<CurrentChatInfo>({"chatID": ""});
  const [modelChat, setModelChat] = useState<string>("sabia-3"); // Default model
  const [userChats, setUserChats] = useState<object[]>([]);
  const [messageHistory, setMessageHistory] = useState<object[]>([]);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [pusherObj, setPusherObj] = useState<Pusher>();
  const {  isAuthenticated , getAccessTokenSilently} = useAuth0();
  

  const providerValue: ChatControlProviderProps = {
    activeChatID,
    setActiveChatID,
    modelChat,
    setModelChat,
    userChats,
    setUserChats,
    messageHistory,
    setMessageHistory,
    loadingHistory,
    setLoadingHistory,
    pusherObj,
  };

  useEffect(() => {
    if (isAuthenticated) {
      const url = API_BASE + "/api/chat/list";
      const fetchchats = async () => {
        const access_token= await getAccessTokenSilently();

        const response = await fetch(url, {
          headers: {
            Authorization: `Auth ${access_token}`,
          },
          method: 'GET',
          }
          );
        const data = await response.json();
        setUserChats(data["chats"]);
      }
      fetchchats();
    }
  }, [isAuthenticated]);

  // fetch message history for active chat
  useEffect(() => {
    if (isAuthenticated && activeChatID["chatID"] != "") {
      const url = API_BASE + "/api/chat/history/" + activeChatID["chatID"];
      const fetchHistory = async () => {
        if (activeChatID["skipUpdate"]){
          return;
        }
        const access_token= await getAccessTokenSilently();
        
        setLoadingHistory(true)
        const response = await fetch(url, {
          headers: {
            Authorization: `Auth ${access_token}`,
          },
          method: 'GET',
          }
          );
        
        if (response.ok){
          // if there are no messages and the chatid==message[0] chatid skip
          const data = await response.json();

          if (data["messages"].length > 0 || messageHistory.length==0 || (messageHistory[0] as any)["chat_id"]!= activeChatID["chatID"] ){
            // if theere are messages in the server side history, update them
            // if there are no messages locally, update them
            // if the local message chatid is different from the active chatid, update them

            // Process the filenames
            data["messages"].forEach((message: any) => {
              if (message["files"] != null){
                message["filenames"] = message["files"].map((file: any) => file["filename"]);
              }
            });
            setMessageHistory(data["messages"]);


            if (data['model'] == undefined){ 
              // If modelChat is null, this is a blank chat windows.
              if (modelChat == undefined)
                setModelChat('sabia-3'); // Default model
            }
            else
              setModelChat(data["model"]);
          }
        }else{
          setMessageHistory([])
        }
        setLoadingHistory(false)

      }
      fetchHistory();
    }else if (isAuthenticated && activeChatID["chatID"] == ""){
      setMessageHistory([])
    }
  }, [activeChatID]);



  return (
    <ChatControlContext.Provider value={providerValue}>
      {children}
    </ChatControlContext.Provider>
  );
};

export default ChatControlContext;
