import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import styled, { StyledComponent } from 'styled-components';
import MaritacaLogo from '../static/images/maritaca_logo.png'
import {toast, ToastContainer} from 'react-toastify';
import { TOAST_CONFIG, PRODUCT_TERMS_AND_POLICY } from '../shared/Constants';

// Define the main app component
function Login() {
  const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();

  // get url params
  const errorMessage = new URLSearchParams(window.location.search).get("error");
  const errorDescription = new URLSearchParams(window.location.search).get("error_description");

  if (errorMessage) {
    toast.error(errorDescription, TOAST_CONFIG);
    // logout({ logoutParams: { returnTo: window.location.origin + "/auth" }})
  }

 
  const navigate = useNavigate();

  if (!isLoading && isAuthenticated) {
    navigate("/")
  }
  
  const handleLogoutButton= ()=>{
    logout({ logoutParams: { returnTo: window.location.origin+ "/auth"}})
  }

  return (
    <>
    <LoginPageWrapper>
      <ToastContainer />
      <LoginPageLogo src={MaritacaLogo} alt="Maritaca Logo" />
      <h1>Bem vindo à MariTalk</h1>
      <span>Acesse sua conta para continuar</span>

      <ButtonArea>
        <ActionButton onClick={() => loginWithRedirect()}>Entrar</ActionButton>
      </ButtonArea>

      <PrivacyLink href={PRODUCT_TERMS_AND_POLICY}>Termos e condições</PrivacyLink>

    </LoginPageWrapper>

    </>
    );
}

export default Login;

const LoginPageWrapper=styled.div`

  display: flex;
  flex:1;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1{
    font-size: 2rem;
    margin-bottom: 0.2rem;
  } 

  & > span{
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
`

const ButtonArea=styled.div`

  display: flex;
  flex-direction: row;
`

const PrivacyLink=styled.a`
  font-size: 1.0rem;
  position: fixed;
  bottom: 1rem;
`

const ActionButton = styled.div`
  background-color: #1d2752;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 10px;
  padding: 6px 100px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
      background-color: #2b3975;
  }
`

const LoginPageLogo=styled.img`
  max-width: 8rem;
`

// new things 

// we need to store the messages in the BackupOutlined, allowing for the user to go back and forth between chats.
//     that requires a databaset ( or writing in a file )
//     this also allows me to put old messages in the chatgpt context 

// auth?
// there needs to be a way to identify the user to allow him to retrieve his chats, oauth0  or firebase auth might be the best bet

//overall user interface improvements
// toasts for when things go wrong
// a method to know when a new chat is ready
// metadata of the conversation? maybe just the title of the video sent

// test in docker 
// online with strict limits? videos with less than 5 minutes maybe?

